<template>
    <div class="productpage">
        <top-user-bar
                :userPhoto="$store.getters.userinfo.avatar"
                :userName="$store.getters.userinfo.nickName"
                :city="$store.getters.city.name"
                :ifRightLink="false"
        ></top-user-bar>
        <van-nav-bar title="商品详情" left-arrow @click-left="$router.back()"/>

        <div class="productbody">
            <van-swipe :loop="true" @change="switChange">
                <van-swipe-item v-for="(image, index) in productBanner" :key="index">
                    <template v-if="index == 0 ">
                        <template v-if="goodsInfo.videoTranscodeStatus == 2">
                            <template v-if="showVideoModel == true">
                                <video ref="video" id="homeVideo" class="home-video" controls autoplay :poster="image">
                                    <source :src="goodsInfo.videoTranscodeUrl" type="video/mp4">
                                </video>
                            </template>
                            <template v-else>
                                <img :src="image" alt=""/>
                                <span class="video-box">
                <van-icon
                        name="play-circle-o"
                        @click.self="showVideo"
                        size="60"
                        color="#fff"
                />
              </span>
                            </template>
                        </template>
                        <template v-else>
                            <img :src="image" alt=""/>
                        </template>
                    </template>
                    <template v-else>
                        <img :src="image" alt=""/>
                    </template>
                </van-swipe-item>
            </van-swipe>
            <div class="topinfo" v-if="goodsInfo.specs">
                <div class="title">
                    {{ goodsInfo.title }}
                    <span class="free" v-if="goodsInfo.productType == 2">包邮</span>
                </div>
                <div class="desc">
                    {{ goodsInfo.content.description }}
                </div>
                <div class="price">
                    <span class="realprice">￥{{ chose.money }}</span>
                    <span class="oldprice">门市价￥{{ chose.marketMoney }}</span>
                    <span class="backprice">最高返￥{{ money }}</span>
                </div>
                <div class="info">
                    <div class="left">已售：{{ chose.showSales }}</div>
                    <div class="right">库存：{{ chose.stock }}</div>
                </div>
            </div>
            <h2>商家信息</h2>
            <div class="storeinfo" v-if="goodsInfo.shopInfo">
                <div class="left" @click="shopaddress(goodsInfo.shopInfo)">
                    <h3>{{ goodsInfo.shopInfo.name }}</h3>
                    <p>{{ goodsInfo.shopInfo.address }}</p>
                </div>
                <div class="phone">
                    <a class="phonelink" :href="'tel:' + goodsInfo.shopInfo.phone"></a>
                </div>
            </div>
            <h2>规格选择</h2>
            <div class="specs">
                <van-radio-group v-model="radio" @change="changeSpecs">
                    <van-radio v-for="r in goodsInfo.specs" :key="r.id" :name="r.id">
                        <template #icon="props">
                            <img class="img-icon" :src="props.checked ? '' : ''"/>
                            {{ r.name }}
                        </template>
                    </van-radio>
                </van-radio-group>
            </div>
            <h2>详细信息</h2>
            <div class="details" v-if="goodsInfo.content">
<!--                <p style="color: red; margin-bottom: 10px">-->
<!--                    此商品{{ goodsInfo.isRefund ? "" : "不" }}支持退款-->
<!--                </p>-->
<!--                <p style="color: red; margin-bottom: 10px">-->
<!--                    {{-->
<!--                    goodsInfo.isLongValidate-->
<!--                    ? "长期有效"-->
<!--                    : "有效期: " +-->
<!--                    goodsInfo.validateStartTimeFormat +-->
<!--                    "——" +-->
<!--                    goodsInfo.validateEndTimeFormat-->
<!--                    }}-->
<!--                </p>-->
                <div v-html="goodsInfo.content.content" class=""></div>
            </div>
        </div>
        <div class="productbottom">
            <router-link to="/">商城首页</router-link>
            <!--            <router-link to="">咨询客服</router-link>-->
            <van-button type="danger" @click="buy" :disabled="disable">{{
                disable ? "商品售罄" : "立即购买"
                }}
            </van-button>
        </div>

        <div class="rightmenu" v-if="$store.getters.userinfo.userLevel > 0 && rightShow">
            <van-button
                    round
                    plain
                    class="close"
                    icon="cross"
                    size="mini"
                    type="danger"
                    @click="close()"
            />
            <div class="title">会员分享</div>
            <div class="fan">最高返 ￥ {{ money }}</div>
            <div class="info">下单既得佣金</div>
            <van-button round type="danger" size="mini" @click="shareAlert"
            >立即分享
            </van-button
            >
        </div>

        <van-overlay :show="showModel" @click="showModel = false">
            <div class="wrapper" @click.stop>
                <div class="block"/>
                <img
                        style="float: right; margin-right: 20px"
                        src="../assets/share.png"
                        alt=""
                />
                <img
                        style="position: absolute;left: 2rem; bottom: 4.5rem;"
                        src="../assets/share2.png" alt="">
            </div>
            <div class="share">
                <div class="button">
                    <van-button type="default" size="large" @click="shareHai"
                    >产品宣传海报
                    </van-button
                    >
                </div>
            </div>
        </van-overlay>
        <!--    <van-overlay :show="showVideoModel" @click="showVideoModelClose">-->
        <!--      <div class="video-preview">-->
        <!--       -->
        <!--      </div>-->
        <!--    </van-overlay>-->
    </div>
</template>
<script>
  // @ is an alias to /src
  import TopUserBar from "@/components/TopUserBar.vue";
  import {addShare, getGoodsInfo} from "@/api/goods";
  import {Notify} from "vant";
  import {getWxConfig} from "@/api/wx";
  import VideoPlay from "@/components/VideoPlay";
  import {userBind, visitorLog} from "@/api/user";

  export default {
    name: "periphery",
    data () {
      return {
        radio: 0,
        rightShow: true,
        loading: false,
        goodsInfo: {},
        productId: 0,
        money: 0,
        showModel: false,
        showVideoModel: false,
        showVideoState: 0,
        autoPlayState: 0,
        chose: {
          money: 0,
          marketMoney: 0,
          commission: 0,
          showSales: 0,
          stock: 0
        },
        productBanner: [],
        disable: false
      };
    },
    methods: {
      changeSpecs () {
        const index = this.goodsInfo.specs.findIndex(
          item => item.id == this.radio
        );
        if (index !== -1) {
          const spec = this.goodsInfo.specs[index];
          (this.chose = {
            money: spec.price,
            marketMoney: spec.marketPrice,
            commission: spec.commission,
            showSales: spec.showSales,
            stock: spec.stock
          }),
            (this.money = spec.shareCommission);
          if (this.chose.stock <= 0) {
            this.disable = true;
          } else {
            this.disable = false;
          }
        }
      },
      getDate (time) {
        const date = this.$moment(new Date(time * 1000));
        return date.format("YYYY/MM/DD");
      },
      close () {
        return (this.rightShow = false);
      },
      showVideo () {
        this.showVideoModel = true;
        this.autoPlayState = Math.random();
      },
      switChange (e) {
        // eslint-disable-next-line no-empty
        if (e !== 0) {
          if (typeof this.$refs.video !== 'undefined') {
            let video = this.$refs.video[0];
            video.pause();
          }
        }
      },

      // 购买商品
      buy () {
        if (this.radio <= 0) {
          Notify({type: "warning", message: "请选择商品规格"});
          return;
        }
        this.$store.commit("SET_BUY_PRODUCT", {
          product: this.productId,
          spec: this.radio
        });
        this.$router.push("/buy");
      },
      async loadGoodsInfo () {
        this.productId = this.$route.params.id;
        if (this.productId <= 0) {
          this.$router.back();
          return;
        }
        this.loading = true;
        const res = await getGoodsInfo({
          productId: this.productId
        });

        if (res.success == true) {
          this.goodsInfo = res.data;
          let productBanner = [];
          if (res.data.videoTranscodeStatus === 2) {
            productBanner.push(res.data.videoTranscodeImg);
          }
          //productBanner
          res.data.productImgs.forEach(function (v) {
            productBanner.push(v);
          });
          this.productBanner = productBanner;

          this.radio = this.goodsInfo.specs[0].id;
          this.changeSpecs();
          // 计算当前价格
          // this.money = res.data.shareCommission;
        }
        this.loading = false;
      },
      async loadWxConfig () {
        const url = window.location.href;
        const res = await getWxConfig(url);
        if (res.success == true) {
          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"]
          });
          this.share();
        }
      },
      share () {
        var _this = this;
        wx.ready(function () {
          const link = window.location.protocol + '//' + window.location.host + '/productpage/' + _this.productId + '?ppid=' + _this.$store.getters.userinfo.id;
          wx.updateAppMessageShareData({
            title: _this.goodsInfo.title,
            desc: _this.goodsInfo.title,
            link: link,
            imgUrl: _this.goodsInfo.billPic,
            success: function () {
              this.sharePost()
            }
          });
          wx.updateTimelineShareData({
            title: _this.goodsInfo.title,
            desc: _this.goodsInfo.title,
            link: link,
            imgUrl: _this.goodsInfo.billPic,
            success: function () {
              // 设置成功
              this.sharePost()
            }
          });
        });
      },
      sharePost () {
        addShare(this.productId).then()
      },
      shareAlert () {
        this.showModel = true;
      },
      shopaddress (shop) {
        window.localStorage.setItem("addressLat", JSON.stringify(shop));
        this.$router.push({name: "addressLat"});
      },
      shareHai () {
        window.location.href = "/hai/" + this.productId;
      }
    },
    created () {
      this.loadGoodsInfo();
      this.loadWxConfig();
      this.sharePost();
      // 记录pid
      const pid = this.$route.query.pId;
      if (pid) {
        // this.$store.commit('SET_PID', pid);
        // 绑定关系
        userBind(pid).then(() => {
        });
        //
        visitorLog({
          productId: this.productId,
          uid: pid
        }).then(data => {
        })
      }

      const ppid = this.$route.query.ppid;

      if (ppid > 0) {
        this.$store.commit('SET_PID', ppid);
      }


    },
    components: {
      "top-user-bar": TopUserBar,
      VideoPlay
    }
  };
</script>
<style scoped lang="scss">
    .share .button {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    #homeVideo {
        width: 100%;
        height: 100%;
    }

    .productpage {
        text-align: left;

        .van-swipe {
            height: 180px;
            overflow: hidden;
        }

        .van-swipe img {
            width: 100%;
        }

        .video-box {
            position: absolute;
            z-index: 1000;
            bottom: 30%;
            right: 40%;
            text-align: center;
            display: block;
        }

        .topinfo {
            text-align: left;
            padding: 10px 15px;
            background: #fff;

            .title {
                font-size: 15px;

                .free {
                    color: #fff;
                    font-size: 12px;
                    background: #d50606;
                    padding: 2px 5px;
                    border-radius: 4px;
                }
            }

            .price {
                padding: 10px 0;

                span {
                    display: inline-block;
                    vertical-align: middle;
                }

                .realprice {
                    font-size: 16px;
                    color: #d50606;
                }

                .oldprice {
                    font-size: 12px;
                    color: #999;
                    text-decoration: line-through;
                    padding: 0 10px;
                }

                .backprice {
                    font-size: 12px;
                    color: #fff;
                    background: rgb(240, 209, 1);
                    padding: 2px 8px;
                    border-radius: 4px;
                }
            }

            .info {
                display: flex;
                justify-content: space-between;
            }

            .desc {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        h2 {
            padding: 7px 15px;
            font-size: 15px;
            font-weight: normal;
        }

        .storeinfo {
            display: flex;
            justify-content: space-between;
            background: #fff;
            padding: 10px 15px;

            .left {
                background: url(../assets/location.svg) no-repeat left center;
                background-size: auto 60%;
                padding-left: 30px;
            }

            h3 {
                font-size: 14px;
                color: #d50606;
            }

            p {
                font-size: 12px;
                color: #666;
            }

            .phonelink {
                display: block;
                width: 40px;
                height: 40px;
                background: url(../assets/phone.svg) no-repeat center center;
                background-size: 45%;
            }
        }

        .van-radio-group {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px 10px 0;
            background: #fff;

            .van-radio {
                margin-bottom: 10px;
            }
        }

        .details {
            padding: 15px;
            background: #fff;
            min-height: 200px;
        }

        .productbody {
            padding-bottom: 45px;
        }

        .productbottom {
            height: 45px;
            position: fixed;
            bottom: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #f7f8fa;

            a,
            a:visited {
                display: block;
                flex: 1;
                text-align: center;
                color: #333;
            }

            .van-button {
                flex: 1;
            }
        }

        .rightmenu {
            position: fixed;
            bottom: 300px;
            right: 0;
            background: #fff;
            box-shadow: 0 0 3px #ddd;
            padding: 0 0 10px 0;
            border-radius: 6px;
            text-align: center;
            overflow: hidden;

            .title {
                background: #d50606;
                color: #fff;
                font-size: 14px;
                padding: 3px;
            }

            .fan {
                padding: 5px;
                color: #d50606;
                font-size: 12px;
            }

            .info {
                font-size: 12px;
                color: #666;
            }

            .van-button {
                padding: 0 15px;
            }

            .van-button.close {
                padding: 0;
                position: absolute;
                right: 0;
                top: 0;
                height: 14px;
                border: 1px solid #d50606;
            }
        }

        .video-preview {
            position: relative;
            top: 20%;
        }
    }
</style>
